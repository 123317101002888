import React from 'react';

export default function FooterMobileAppButton({ href, icon, text, alt }) {
	return (
		<a
			className="footer-button w-36 sm:w-48 flex justify-center items-center sm:mr-3 my-2 md:my-0 text-base"
			target="_blank"
			rel="noreferrer"
			href={href}
		>
			<img loading="lazy" src={icon} alt={alt} className="w-4 mr-3" />
			<span className="font-poppins text-sm sm:text-base">{text}</span>
		</a>
	);
}
