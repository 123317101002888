import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog } from '@material-ui/core';

const BasicModal = ({ open, children }) => (
	<Dialog open={open}>{children}</Dialog>
);

BasicModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(BasicModal);
