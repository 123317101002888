import React, { Component } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { bindActionCreators } from 'redux';

import { Toolbar } from '@material-ui/core';
import Sidenav from '../Sidenav';
import CountriesLogin from '../../home/CountriesLogin';
import { SnackBar } from 'components/widgets';
import MegaMenu from '../MegaMenu';
import { googleLogout } from '../../utils/GoogleLogout';

const logo = '/static/images/Asistensi_logotipo.svg';

class AllyNav extends Component {
	state = {
		anchorEl: null,
		display: true,
		isProfile: false,
		showLead: false,
		openAlert: false,
		messageAlert: '',
		anchorFlag: null
	};

	handleClose = () => this.setState({ anchorEl: null });

	showDisplay = () => this.setState({ display: true, anchorEl: null });

	handleSignOut = () => {
		this.props.actions.save_token({ token: '' });
		this.props.actions.save_titular_email({ email: '' });
		this.props.actions.save_user_type({ user_type: 0 });
		this.props.actions.cleanAffiliation();
		googleLogout();
		this.props.actions.Logout();
		Router.push('/authentication/signIn', '/login');
	};

	componentDidMount = async () => {
		await this.handleNavbarState();

		// class is lost in close session button with this it is fixed
		const element = document.querySelector(
			'#__next > div > div > main > nav > div > div > ul > button'
		);
		if (element) element?.setAttribute('class', 'white-button-nav min-w-28');
	};

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			await this.handleNavbarState();
		}
	}

	handleNavbarState = async () => {
		const pathname = (await this.props.pathname) ? this.props.pathname : '/';
		if (
			pathname === '/profile/affiliates/members' ||
			pathname === '/profile/affiliates/my-affiliates' ||
			pathname === '/profile/affiliates/my-balance' ||
			// pathname === '/profile/affiliates/means' ||
			pathname === '/profile' ||
			pathname === '/profile/covered-member' ||
			pathname === '/profile/advanced-controls' ||
			pathname === '/profile/payment-management' ||
			pathname === '/profile/renewal-management' ||
			pathname === '/profile/update-renewal' ||
			pathname === '/profile/ally/quotation' ||
			pathname === '/profile/affiliates/prizes' ||
			pathname === '/profile/aliado/mi-bigbroder'
		) {
			this.setState({ isProfile: true });
		} else {
			this.setState({ isProfile: false });
		}
	};

	handleSubmitLead = (response) => {
		document.body.classList.remove('modal-open');
		this.setState({ ...response, showLead: false });
	};

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ showLead: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ showLead: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	render() {
		const {
			isAlly,
			isAllyMaster,
			isAllyMatrix,
			great_ally_id,
			company_name,
			full_name,
			no_aply_commission,
			is_promoter,
			token
		} = this.props;

		const { display, isProfile, showLead, messageAlert, openAlert } =
			this.state;

		let menu = [
			{ text: 'Products', url: '/plans', as: '/plans' },
			{ text: 'Health Services', url: '/benefits', as: '/health-services/' },
			{ text: 'Frequent questions', url: '/faqs', as: '/faq' },
			// {
			// 	text: 'Means',
			// 	url: '/profile/ally/resources',
			// 	as: '/profile/affiliates/means'
			// },
			{
				text: 'Recommendations',
				url: '/profile/ally/affiliations',
				as: '/profile/affiliates/members'
			}
		];

		if (isAllyMaster || isAllyMatrix || (isAlly && !no_aply_commission)) {
			let profileRoute = {
				text: 'My profile',
				url: '/profile/beneficiaries',
				as: '/profile/covered-member'
			};
			if ((isAllyMaster && great_ally_id) || isAllyMatrix)
				profileRoute = { text: 'My profile', url: '/profile', as: '/profile' };
			menu.push(profileRoute);
			if (isAllyMatrix && is_promoter) delete menu[3];
		}

		const navigation = menu.map((link, i) => {
			return (
				<React.Fragment key={i}>
					<style>
						{`
              @media only screen and (min-width: 1280px) and (max-width: 1380px) {
                .navbar-selector {
                  font-size: 0.75rem !important;
                }
              }
              @media only screen and (min-width: 960px) and (max-width: 1120px) {
                .navbar-selector {
                  font-size: 0.55rem !important;
                  padding-right: .8rem !important;
                }
              }
            `}
					</style>
					<li
						className={`navbar-selector md:text-small lg:text-xs text-gray-750 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500 ${
							this.state.ocult === true ? 'hidden' : 'inline-block'
						}`}
					>
						<Link href={link.url} as={link.as} passHref>
							<a
								className={`nav-text
								${link.url === '/freemium' ? 'text-pink-500' : ''}
								${
									Router.router &&
									Router.router.pathname &&
									Router.router.pathname === link.url
										? 'text-pink-500'
										: ''
								}`}
							>
								{link.text}
							</a>
						</Link>
					</li>
				</React.Fragment>
			);
		});

		return (
			<nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full flex flex-col">
				{!isProfile && (
					<div className="order-2 md:order-first">
						<MegaMenu
							showDialog={this.showDialog}
							closeDialog={this.closeDialog}
							showLead={showLead}
							handleSubmitLead={this.handleSubmitLead}
						/>
					</div>
				)}
				<Toolbar
					className={`flex justify-between padding-0 ${
						isProfile ? 'container-profile' : 'container'
					}`}
				>
					<Sidenav
						navigation={menu}
						display={display}
						handleSignOut={this.handleSignOut}
						isProfile={true}
					/>
					<div className="flex justify-between items-center hidden w-full md:flex">
						{this.state.isProfile === true ? (
							<div className="text-xs text-gray-800">
								<span className="font-bold capitalize">
									Hello{' '}
									<span>
										{(isAllyMatrix && !is_promoter) ||
										(isAllyMaster && great_ally_id)
											? company_name
											: full_name}
									</span>
								</span>
								<span className="font-normal"> Welcome </span>
								<Link href="/">
									<span className="text-pink-500 cursor-pointer">
										asistensi
									</span>
								</Link>
							</div>
						) : (
							<Link href="/">
								<img
									loading="lazy"
									alt="Logo Asistensi"
									src={logo}
									className="w-auto h-12 cursor-pointer"
								/>
							</Link>
						)}
						<ul className="flex items-center justify-evenly">
							{navigation}

							<button
								onClick={() => this.handleSignOut()}
								className="white-button-nav min-w-28 md:text-small lg:text-xs"
							>
								Logout
							</button>

							{token && <CountriesLogin />}
						</ul>
					</div>
				</Toolbar>
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleCloseAlert}
				/>
			</nav>
		);
	}
}

function mapStateToProps(state) {
	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;
	const great_ally_id = state.login.get('great_ally_id');
	const no_aply_commission = state.login.get('no_aply_commission');
	const company_name = state.login.get('company_name');
	const full_name = state.login.get('full_name');
	const is_promoter = state.login.get('is_promoter');
	const token = state.affiliation.get('token');

	return {
		isAllyMatrix,
		isAllyMaster,
		isAlly,
		great_ally_id,
		no_aply_commission,
		company_name,
		full_name,
		is_promoter,
		token
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AllyNav);
