import React, { useState } from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Popover, Button } from '@material-ui/core';
import asistensiCountries from 'components/utils/asistensiCountries';

const COUNTRY_NAME = process.env.REACT_APP_COUNTRY_CODE;

const CountriesLogin = (props) => {
	const [anchorFlag, setAnchorFlag] = useState(null);

	const handleOpenFlag = (event) => {
		setAnchorFlag(event.currentTarget);
	};

	const handleCloseFlag = () => {
		setAnchorFlag(null);
	};

	/**
	 * It opens a new tab with the url of the country that was passed in
	 * @param country - The country code of the country you want to open the asistensi page for.
	 * @returns the value of the variable response.hash.
	 */
	const generateHash = async (country) => {
		const { actions, token } = props;
		const urls = asistensiCountries;

		if (token) {
			const response = await actions.generateSessionHash();
			window.open(`${urls[country]}?hash=${response.hash}`, '_blank');
			return;
		}
		window.open(`${urls[country]}`, '_blank');
	};

	return (
		<>
			<div className="hidden md:block">
				<Button
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleOpenFlag}
				>
					<img
						loading="lazy"
						src="/images/ph.svg"
						alt={`${COUNTRY_NAME} flag`}
						className="h-7"
					/>
				</Button>
				<Popover
					id="simple-menu"
					anchorEl={anchorFlag}
					keepMounted
					open={Boolean(anchorFlag)}
					onClose={handleCloseFlag}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<div className="flex flex-col bg-white p-3">
						<span className="cursor-pointer" onClick={() => generateHash('ve')}>
							<img
								loading="lazy"
								src="/images/ve.svg"
								alt="Flag of Venezuela"
								className="h-7 mb-3"
							/>
						</span>
						<span className="cursor-pointer" onClick={() => generateHash('do')}>
							<img
								loading="lazy"
								src="/images/do.svg"
								alt="Dominican Republic flag"
								className="h-7 mb-3"
							/>
						</span>
						<span className="cursor-pointer" onClick={() => generateHash('mx')}>
							<img
								loading="lazy"
								src="/images/mx.svg"
								alt="Flag of Mexico"
								className="h-7"
							/>
						</span>
						<span
							className="cursor-pointer mt-3"
							onClick={() => generateHash('hn')}
						>
							<img
								loading="lazy"
								src="/images/hn.svg"
								alt="Honduran flag"
								className="h-7"
							/>
						</span>
					</div>
				</Popover>
			</div>
			<div className="flex md:hidden flex-row items-center px-4 py-2">
				<span className="cursor-pointer" onClick={() => generateHash('ve')}>
					<img
						loading="lazy"
						src="/images/ve.svg"
						alt="Flag of Venezuela"
						className="h-7"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('do')}>
					<img
						loading="lazy"
						src="/images/do.svg"
						alt="Bandera de República Dominicana"
						className="h-7 ml-3"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('mx')}>
					<img
						loading="lazy"
						src="/images/mx.svg"
						alt="Bandera de México"
						className="h-7 ml-3"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('hn')}>
					<img
						loading="lazy"
						src="/images/hn.svg"
						alt="Bandera de Honduras"
						className="h-7 ml-3"
					/>
				</span>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	token: state.affiliation.get('token')
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CountriesLogin);
