import React, { Component } from 'react';
import Link from 'next/link';
import Router from 'next/router';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

import {
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const logo = '/static/images/Asistensi_logotipo.svg';

class Sidenav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			top: false,
			left: false,
			bottom: false,
			right: false
		};
	}

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	render() {
		const { logged, handleLogOut } = this.props;
		const { right } = this.state;

		return (
			<div className="flex items-center justify-between min-h-64p w-full md:hidden">
				<style global jsx>
					{`
						.side-nav-asistensi .border-left-side-nav:hover,
						.side-nav-asistensi .border-left-side-nav:active,
						.side-nav-asistensi .border-left-side-nav:focus {
							border-left: 5px solid #fb314a;
							background: rgba(0, 0, 0, 0);
							transition: all 300ms ease-in-out;
						}

						.side-nav-asistensi .border-left-side-nav:hover div span,
						.side-nav-asistensi .border-left-side-nav:focus div span,
						.side-nav-asistensi .border-left-side-nav:active div span {
							color: #fb314a;
						}

						.side-active-link .border-left-side-nav {
							border-left: 5px solid #fb314a;
						}

						.side-active-link .border-left-side-nav div span {
							color: #fb314a;
						}
					`}
				</style>
				<Link href="/">
					<img
						loading="lazy"
						alt="Logo Asistensi"
						src={logo}
						className="h-10 w-auto cursor-pointer"
					/>
				</Link>
				<button
					className="hover:bg-purple-500 p-1 rounded transition duration-300 ease-in"
					onClick={this.toggleDrawer('right', true)}
				>
					<MenuIcon className="text-purple-500 hover:text-white" />
				</button>
				<Drawer
					anchor="right"
					open={right}
					onClose={this.toggleDrawer('right', false)}
					className="side-nav-asistensi"
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer('right', false)}
						onKeyDown={this.toggleDrawer('right', false)}
					>
						<List>
							<div className="nav-bar-tabs fw">
								<div className="fw fje">
									<IconButton
										className="m-r5 p-5"
										onClick={this.toggleDrawer('right', false)}
									>
										<CloseIcon className="text-purple-500 text-normal" />
									</IconButton>
								</div>
								<Link href="/admin/subscriptions" as="/admin/afiliaciones">
									<a
										className={`${
											Router?.router?.pathname === '/admin/subscriptions'
												? 'side-active-link'
												: ''
										}`}
									>
										<ListItem button className="border-left-side-nav">
											<ListItemText
												className="nav-text"
												primary="Administración"
											/>
										</ListItem>
									</a>
								</Link>
							</div>
							{logged && (
								<ListItem button className="border-left-side-nav">
									<ListItemText
										className="vida-color"
										primary="Logout"
										onClick={handleLogOut}
									/>
								</ListItem>
							)}
						</List>
					</div>
				</Drawer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	// user data
	const logged = state.login.get('logged');

	return { logged };
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
