import React from 'react';

export default function FooterPhoneNumberLink({ callNumber, number, country }) {
	return (
		<span>
			<span className="text-white text-sm">{country}:</span>&nbsp;
			<a href={`tel:${callNumber}`} rel="noopener noreferrer">
				<span className="text-pink-500 text-sm">{number}</span>
			</a>
		</span>
	);
}
