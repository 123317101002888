export default function getOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// // Windows phone detection
	// if (/windows phone/i.test(userAgent)) return 'Windows phone';
	// // Android detection
	// else if (/android/i.test(userAgent)) return 'Android';
	// iOS detection

	const iOSDevices = [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	];

	// ||	(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	if (
		(/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) ||
		iOSDevices.includes(navigator.platform)
	)
		return 'iOS';

	return 'unknown';
}
