import React from 'react';
import Link from 'next/link';

export default function FooterLink({
	href = '',
	as = null,
	text = '',
	target = '',
	rel
}) {
	return (
		<Link href={href} as={as} passHref>
			<a
				href={href}
				className="text-white w-content leading-relaxed text-sm cursor-pointer font-poppins mt-3"
				target={target}
				rel={rel}
			>
				{text}
			</a>
		</Link>
	);
}
