import React from 'react';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const facebook = `${serverUrlImages}/icons/contact/facebook.svg`;
const linkedIn = `${serverUrlImages}/icons/contact/linked-in.svg`;
const twitter = `${serverUrlImages}/icons/contact/twitter.svg`;
const instagram = `${serverUrlImages}/icons/contact/instagram.svg`;

const facebookWhite = `${serverUrlImages}/icons/facebook.svg`;
const linkedInWhite = `${serverUrlImages}/icons/linked-in.svg`;
const twitterWhite = `${serverUrlImages}/icons/twitter.svg`;
const instagramWhite = `${serverUrlImages}/icons/instagram.svg`;

const socialLinks = [
	{
		name: 'Facebook',
		logo: facebook,
		whiteLogo: facebookWhite,
		href: 'https://www.facebook.com/asistensi.ph'
	},
	{
		name: 'Linkedin',
		logo: linkedIn,
		whiteLogo: linkedInWhite,
		href: 'https://www.linkedin.com/company/asistensi'
	},
	{
		name: 'Twitter',
		logo: twitter,
		whiteLogo: twitterWhite,
		href: 'https://twitter.com/asistensiPH'
	},
	{
		name: 'instagram',
		logo: instagram,
		whiteLogo: instagramWhite,
		href: 'https://www.instagram.com/asistensi.ph'
	}
];

const socialMedia = () => {
	return socialLinks.map(({ logo, name, href }) => (
		<a
			key={`${name}-link`}
			href={href}
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				src={logo}
				alt={`${name}-social`}
				className="w-5 c-p icon-hover m-r20"
			/>
		</a>
	));
};

export { socialLinks, socialMedia };
