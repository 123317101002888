import React, { Component } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { bindActionCreators } from 'redux';

import { Toolbar } from '@material-ui/core';
import Sidenav from '../Sidenav';
import CountriesLogin from '../../home/CountriesLogin';
import { SnackBar } from 'components/widgets';
import MegaMenu from '../MegaMenu';
import { googleLogout } from '../../utils/GoogleLogout';
import { commonNav } from '../../utils/menuNav';

const logo = '/static/images/Asistensi_logotipo.svg';

class ClientNav extends Component {
	state = {
		anchorEl: null,
		display: true,
		isProfile: false,
		showLead: false,
		openAlert: false,
		messageAlert: '',
		ocultMegaMenu: false
	};

	handleClose = () => this.setState({ anchorEl: null });

	showDisplay = () => this.setState({ display: true, anchorEl: null });

	handleSignOut = () => {
		const { actions } = this.props;
		actions.save_token({ token: '' });
		actions.save_titular_email({ email: '' });
		actions.save_user_type({ user_type: 0 });
		actions.cleanAffiliation();
		actions.setSignupData({});
		googleLogout();
		actions.Logout();
		actions.setMembershipOrigin('login');
		Router.push('/authentication/signIn', '/login');
	};

	componentDidMount = async () => await this.handleNavbarState();

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			await this.handleNavbarState();
		}
	}

	handleNavbarState = async () => {
		const pathname = (await this.props.pathname) ? this.props.pathname : '/';
		if (
			pathname === '/profile' ||
			pathname === '/profile/covered-member' ||
			pathname === '/profile/advanced-controls' ||
			pathname === '/profile/payment-management' ||
			pathname === '/profile/renewal-management' ||
			pathname === '/profile/update-renewal' ||
			pathname === '/profile/sos' ||
			pathname === '/profile/sos/consulta-general/motivo' ||
			pathname === '/profile/sos/consulta-general/antecedentes' ||
			pathname === '/profile/sos/consulta-general/detalle' ||
			pathname === '/profile/sos/consulta-general/datos' ||
			pathname === '/profile/sos/consulta-general/resultados' ||
			pathname === '/profile/sos/consulta-general/resultadoscontacto' ||
			pathname === '/profile/sos/consulta-general/finalizado' ||
			pathname === '/profile/sos/covid-19' ||
			pathname === '/profile/sos/covid-19/resultados'
		) {
			this.setState({ isProfile: true });
		} else {
			this.setState({ isProfile: false });
		}
		if (
			pathname === '/start-registration' ||
			pathname === '/covered-member-registration' ||
			pathname === '/health-information-registration' ||
			pathname === '/afiliacion-servicios-adicionales' ||
			pathname === '/afiliacion-flujo-alterno' ||
			pathname === '/afiliacion-flujo-alterno' ||
			pathname === '/afiliacion-informacion-pago' ||
			pathname === '/ended-process'
		) {
			this.setState({ ocultMegaMenu: true });
		} else {
			this.setState({ ocultMegaMenu: false });
		}
	};

	handleSubmitLead = (response) => {
		document.body.classList.remove('modal-open');
		this.setState({ ...response, showLead: false });
	};

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ showLead: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ showLead: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	render() {
		const { titular, user_type, token } = this.props;

		const { display, isProfile, showLead, messageAlert, openAlert } =
			this.state;

		const menu = [
			{ text: '', url: '/', as: '/', targetBlank: false },
			...commonNav,

			{
				text: 'My account',
				url: '/profile/beneficiaries',
				as: '/profile/covered-member',
				targetBlank: false
			}
		];

		const navigation = menu.map((link, i) => {
			return (
				<React.Fragment key={i}>
					<style jsx>
						{`
							@media only screen and (min-width: 1280px) and (max-width: 1380px) {
								.navbar-selector {
									font-size: 0.75rem !important;
								}
							}
							@media only screen and (min-width: 960px) and (max-width: 1120px) {
								.navbar-selector {
									font-size: 0.55rem !important;
									padding-right: 0.8rem !important;
								}
							}
						`}
					</style>
					<li
						className={`navbar-selector text-small lg:text-xs text-gray-750 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500 ${
							this.state.ocult === true ? 'hidden' : 'inline-block'
						}`}
					>
						<Link href={link.url} as={link.as} passHref>
							<a
								className={`
                  ${link.url === '/' ? 'ml-4' : ''}
                  ${link.url === '/sos' ? 'text-pink-500' : ''}
                  ${
										Router.router &&
										Router.router.pathname &&
										Router.router.pathname === link.url
											? 'text-pink-500'
											: ''
									}
                `}
								target={link.targetBlank ? '_blank' : ''}
								rel={link.targetBlank ? 'noreferrer' : ''}
							>
								{link.text}
							</a>
						</Link>
					</li>
				</React.Fragment>
			);
		});

		return (
			<nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full flex flex-col">
				{!isProfile && !this.state.ocultMegaMenu && (
					<div className="order-2 md:order-first">
						<MegaMenu
							showDialog={this.showDialog}
							closeDialog={this.closeDialog}
							showLead={showLead}
							handleSubmitLead={this.handleSubmitLead}
						/>
					</div>
				)}
				<Toolbar
					className={`flex justify-between padding-0 ${
						isProfile ? 'container-profile' : 'container'
					}`}
				>
					<Sidenav
						navigation={menu}
						display={display}
						handleSignOut={this.handleSignOut}
						isProfile={true}
					/>
					<div className="justify-between items-center hidden w-full md:flex">
						{this.state.isProfile === true ? (
							<div className="text-xs text-gray-800">
								<span className="font-bold capitalize">
									Hello
									{titular && titular.first_name && user_type === 1 && (
										<span> {titular.first_name}</span>
									)}
									!
								</span>
								<span className="font-normal"> Welcome </span>
								<Link href="/">
									<span className="text-pink-500 cursor-pointer">
										asistensi
									</span>
								</Link>
							</div>
						) : (
							<Link href="/">
								<img
									loading="lazy"
									alt="Logo Asistensi"
									src={logo}
									className="w-auto h-10 cursor-pointer"
								/>
							</Link>
						)}
						<ul className="flex items-center justify-evenly">
							{navigation}
							<li>
								<button
									onClick={() => this.handleSignOut()}
									className="white-button-nav min-w-28 text-small lg:text-xs"
								>
									Logout
								</button>
							</li>
							{token && <CountriesLogin />}
						</ul>
					</div>
				</Toolbar>
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleCloseAlert}
				/>
			</nav>
		);
	}
}
function mapStateToProps(state) {
	const titular = state.affiliation.get('titular');
	const user_type = state.affiliation.get('user_type');
	const token = state.affiliation.get('token');

	return {
		titular,
		user_type,
		token
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientNav);
