import React from 'react';

export default function FooterSocialLink({ href, imgSrc, alt }) {
	return (
		<a href={href} target="_blank" rel="noopener noreferrer">
			<img
				src={imgSrc}
				alt={alt}
				loading="lazy"
				className="w-5 cursor-pointer mr-5 ml-5 hover:opacity-50 transition duration-300 ease"
			/>
		</a>
	);
}
