export const phoneNumbers = [
	{
		country: 'Philippines',
		number: '+63 2 8280 1066',
		callNumber: '+63282801066'
	},
	{
		country: 'United States',
		number: '+1 (213) 528 36 12',
		callNumber: '+12135283612'
	},
	{
		country: 'Spain',
		number: '+34 (919) 033 520',
		callNumber: '+34919033520'
	}
];
