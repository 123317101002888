import React from 'react';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneIcon from '@material-ui/icons/Phone';

import ManageLead from 'components/home/ManageLead';

const MegaMenu = ({
	showLead,
	handleSubmitLead,
	showDialog,
	closeDialog,
	hideNumbers
}) => {
	return (
		<>
			<div className="w-full hidden md:flex items-center justify-between py-4 px-5 md:px-20 border-b">
				<div
					className={`flex items-center ${
						hideNumbers ? 'invisible' : 'visible'
					}`}
				>
					<PhoneIcon className="text-base text-pink-500 mr-2" />
					<span className="text-sm font-normal text-gray-800">
						Philippines:&nbsp;
						<a href="tel:+63282801066" rel="noopener noreferrer">
							+63 2 8280 1066
						</a>
						&nbsp;&nbsp;|&nbsp;&nbsp; United States:&nbsp;
						<a href="tel:+12135283612" rel="noopener noreferrer">
							+1 (213) 528 36 12
						</a>
						&nbsp;&nbsp;|&nbsp;&nbsp; Spain:&nbsp;
						<a href="tel:+34919033520" rel="noopener noreferrer">
							+34 (919) 033 520
						</a>
					</span>
				</div>
			</div>

			{/* MOBILE */}
			<div className="w-full md:hidden flex flex-col items-center py-4 border-t">
				<div
					className={`flex items-center mb-3 ${
						hideNumbers ? 'invisible' : 'visible'
					}`}
				>
					<PhoneIcon className="text-base text-pink-500 mr-2" />
					<span className="text-xs text-center font-normal text-gray-800">
						Phillipines:&nbsp;
						<a href="tel:+63282801066" rel="noopener noreferrer">
							+63 2 8280 1066
						</a>
					</span>
				</div>
				<div>
					<a
						className="text-base cursor-pointer text-pink-500 font-bold font-poppins mt-4"
						onClick={() => showDialog()}
					>
						¡Call me!
					</a>
				</div>
			</div>
			{showLead && (
				<ManageLead
					handleClose={() => closeDialog()}
					handleSubmit={handleSubmitLead}
				/>
			)}
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(MegaMenu);
