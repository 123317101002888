/**
 * TODO: ALL THE COUNTRIES WHERE THE asistensi IS WITHOUT "PH"
 */

export default {
	ve: process.env.REACT_APP_DOMAIN_URL_VE,
	do: process.env.REACT_APP_DOMAIN_URL_DO,
	mx: process.env.REACT_APP_DOMAIN_URL_MX,
	hn: process.env.REACT_APP_DOMAIN_URL_HN
};
