export const commonNav = [
	{
		text: 'Plans',
		url: '/plans',
		as: '/plans',
		prefetch: false,
		targetBlank: false
	},
	{
		text: 'Health Services',
		url: '/benefits',
		as: '/health-services',
		prefetch: false,
		targetBlank: false
	},
	{
		text: 'About Us',
		url: '/about',
		as: '/about-us',
		prefetch: false,
		targetBlank: false
	},
	{
		text: 'FAQs',
		url: '/faqs',
		as: '/faq',
		prefetch: false,
		targetBlank: false
	},
	{
		text: 'Contact Us',
		url: '/contact',
		as: '/contact-us',
		prefetch: false,
		targetBlank: false
	}
];

export const menu = [
	{ text: 'Home', url: '/' },
	...commonNav
	/* {
		text: 'asistensi Free',
		url: '/freemium',
		as: '/free-online-doctor/',
		prefetch: false,
		targetBlank: false
	} */
];
