import React from 'react';
import { Toolbar } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';

import { actualYear } from '../utils/Date';
import BasicModal from '../BasicModal/BasicModal';
import ChillModalLaunchApp from '../forms/common/ChildModalLaunchApp';
import { socialLinks } from '../utils/SocialMedia';
import { phoneNumbers } from '../utils/PhoneNumbers';
import FooterSocialLink from './FooterSocialLink';
import FooterMobileAppButton from './FooterMobileAppButton';
import FooterPageLink from './FooterPageLink';
import FooterMobileAppComingSoon from './FooterMobileAppComingSoon';
import FooterPhoneNumberLink from './FooterPhoneNumberLink';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;

const apple = `${serverUrlImages}/icons/apple.svg`;
const android = `${serverUrlImages}/icons/google-play.svg`;
const logo = '/static/images/logo.svg';

const DOMAIN_VE = process.env.REACT_APP_DOMAIN_URL_VE;
const DOMAIN_DO = process.env.REACT_APP_DOMAIN_URL_DO;
const DOMAIN_MX = process.env.REACT_APP_DOMAIN_URL_MX;
const DOMAIN_HN = process.env.REACT_APP_DOMAIN_URL_HN;
const DOMAIN_GLOBAL = process.env.REACT_APP_DOMAIN_URL_GLOBAL;

const sitemap = [
	{
		text: 'About us',
		href: '/about',
		as: '/about-us',
		targetBlank: false
	},
	{
		text: 'Health Services',
		href: '/benefits',
		as: '/health-services',
		targetBlank: false
	},
	/* {
		text: 'asistensi Free',
		href: '/freemium',
		as: '/free-online-doctor',
		targetBlank: false
	}, */
	{ text: 'Contact us', href: '/contact', as: '/contact-us' }
];
const countries = [
	{ text: 'Venezuela', href: DOMAIN_VE },
	{ text: 'República Dominicana', href: DOMAIN_DO },
	{ text: 'México', href: DOMAIN_MX },
	{ text: 'Honduras', href: DOMAIN_HN },
	{
		text: (
			<span>
				<strong>asistensi global</strong>
			</span>
		),
		href: DOMAIN_GLOBAL
	}
];
const information = [
	{
		text: 'FAQs',
		href: '/faq',
		as: '/faq'
	},
	{
		text: 'Terms of use',
		href: '/termscondition',
		as: '/usage-terms-and-conditions'
	},
	{
		text: 'Privacy policies',
		href: '/privacypolicy',
		as: '/privacy-policy'
	},
	{
		text: 'Cookie policies',
		href: '/cookiespolicy',
		as: '/cookies-policy'
	},
	{
		text: 'Legal notice',
		href: '/legalnotice',
		as: '/legal-notice'
	}
];

const Footer = ({
	ios_app_link,
	android_app_link,
	pathname,
	isAppleDevice = false
}) => {
	const [ocult, setOcult] = React.useState(false);
	const [openModalLaunchApp, setOpenModalLaunchApp] = React.useState(false);

	const handleFooterState = async () => {
		const _pathname = pathname ? pathname : '/';

		if (
			_pathname === '/start-registration' ||
			_pathname === '/login' ||
			_pathname === '/customer-registration' ||
			_pathname === '/optional-customer-registration' ||
			// Profile
			_pathname === '/profile' ||
			_pathname === '/profile/covered-member' ||
			_pathname === '/profile/advanced-controls' ||
			_pathname === '/profile/payment-management' ||
			_pathname === '/profile/renewal-management' ||
			_pathname === '/profile/update-renewal' ||
			// _pathname === '/profile/sos' ||
			// _pathname === '/profile/sos/consulta-general/motivo' ||
			// _pathname === '/profile/sos/consulta-general/antecedentes' ||
			// _pathname === '/profile/sos/consulta-general/detalle' ||
			// _pathname === '/profile/sos/consulta-general/datos' ||
			// _pathname === '/profile/sos/consulta-general/resultados' ||
			// _pathname === '/profile/sos/consulta-general/resultadoscontacto' ||
			// _pathname === '/profile/sos/consulta-general/finalizado' ||
			// _pathname === '/profile/sos/covid-19' ||
			// _pathname === '/profile/sos/covid-19/resultados' ||
			// Afilliation
			_pathname === '/afiliacion-informacion-pago' ||
			_pathname === '/afiliacion-flujo-alterno' ||
			_pathname === '/cotizacion' ||
			_pathname === '/ended-process' ||
			// Ally profile
			_pathname === '/profile/affiliates/members' ||
			_pathname === '/profile/affiliates/my-affiliates' ||
			_pathname === '/profile/affiliates/my-balance' ||
			_pathname === '/profile/affiliates/means' ||
			_pathname === '/profile/ally/quotation' ||
			_pathname === '/profile/affiliates/prizes' ||
			_pathname === '/profile/aliado/mi-bigbroder' ||
			// App routes
			_pathname === '/m/terminos-uso' ||
			_pathname === '/m/politica-privacidad'
		) {
			setOcult(true);
		} else {
			setOcult(false);
		}
	};

	React.useEffect(() => {
		handleFooterState();
	}, []);

	React.useEffect(() => {
		handleFooterState();
	}, [pathname]);

	return (
		<footer
			className={`static bg-purple-500 ${ocult === true ? 'hidden' : ''}`}
		>
			<BasicModal
				open={openModalLaunchApp}
				onClose={() => setOpenModalLaunchApp(false)}
			>
				<ChillModalLaunchApp onClose={() => setOpenModalLaunchApp(false)} />
			</BasicModal>
			<Toolbar className="container flex flex-col padding-x-0 pb-28 pt-20 md:py-20">
				<div className="flex flex-col md:flex-row md:justify-between mb-14 md:mb-0">
					<img
						src={logo}
						alt="logo asistensi"
						className="h-10 w-auto mb-8 sm:hidden"
					/>
					<div className="w-full mb-10 md:mb-0 md:w-6/12 ">
						{/* legal copy */}
						<p className="text-white font-poppins mb-10 text-justify text-14px">
							The health coverages for hospitalization and surgery in health
							centers are provided by Maxicare Healthcare Corporation, a company
							duly authorized to operate in the Republic of the Philippines and
							registered with the Insurance Commission under number
							HMO-2020-03-R, are associated with the health plans marketed by
							Asistensi Philippines, Inc. and are duly supervised by the
							Insurance Commission of the Republic of the Philippines.
						</p>

						{/* mobile apps */}
						{!isAppleDevice && (
							<div className="flex md:justify-start justify-center items-center flex-wrap w-full">
								<FooterMobileAppButton
									alt="apple icon"
									href={`${ios_app_link}`}
									icon={apple}
									text="App Store"
								/>
								<FooterMobileAppButton
									href={`${android_app_link}`}
									alt="android icon"
									icon={android}
									text="Google Play"
								/>
							</div>
						)}

						<a
							href="https://www.asistensi.com"
							rel="noopener noreferrer"
							target="_blank"
							className="mx-auto md:mx-0 flex w-content items-start  py-2 mt-6"
						>
							<PublicIcon className="text-white mr-2" />
							<span className="text-white text-sm font-poppins">
								Visit <b>Global Asistensi</b>
							</span>
						</a>
					</div>

					<div className="w-full items-center md:items-start md:w-4/12 flex flex-col">
						{/* website map */}
						<div className="flex justify-start sm:justify-center md:justify-start sm:w-full w-full">
							<div className="flex flex-col mr-8 md:mx-0">
								<p className="text-pink-500 text-lg font-bold font-poppins hidden sm:block">
									About asistensi
								</p>
								<div className="flex flex-col">
									{sitemap.map((site, index) => (
										<FooterPageLink
											{...site}
											index={index}
											key={`site-link-${index}`}
										/>
									))}
								</div>
							</div>

							<div className="flex flex-col mx-4 md:ml-20 md:mx-0">
								<p className="text-pink-500 text-lg font-bold font-poppins hidden sm:block">
									Information
								</p>
								<div className="flex flex-col">
									{information.map((site, index) => (
										<FooterPageLink
											{...site}
											index={index}
											key={`site-link-${index}`}
										/>
									))}
								</div>
							</div>
						</div>

						{/* phone numbers*/}
						<div className="flex flex-col mt-10 w-full sm:w-content">
							{phoneNumbers.map(({ callNumber, country, number }) => (
								<FooterPhoneNumberLink
									key={`${country}-phone`}
									callNumber={callNumber}
									country={country}
									number={number}
								/>
							))}
						</div>
					</div>
				</div>

				<div className="w-full flex flex-col justify-center md:justify-between items-center mt-8 border-t border-white border-opacity-25 pt-8 sm:flex-row md:pt-16 md:mt-16 md:flex-row md:mb-0">
					<div className="flex sm:order-2 md:order-3 md:items-center">
						{socialLinks.map(({ name, href, whiteLogo }) => (
							<FooterSocialLink
								key={`footer-social-${name}`}
								href={href}
								imgSrc={whiteLogo}
								alt={`${name} logo`}
							/>
						))}
					</div>
					<img
						loading="lazy"
						src={logo}
						alt="logo asistensi"
						className="h-10 w-auto order-2 hidden md:block"
					/>
					<span className="text-white text-xs mt-8 sm:mt-0">
						©{actualYear} <span className="font-bold">Asistensi</span> Global
						Insurance, Inc.
					</span>
				</div>
			</Toolbar>
		</footer>
	);
};

export default Footer;
